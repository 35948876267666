<template lang="pug">
.flex.bg-custom
  navbar
  .container.pt-36.px-3
    router-view 
</template>
<script>
import navbar from "./navbar";
export default {
  name: "body-structure",
  components: {
    navbar,
  },
  async created() {
    await this.$store.dispatch("orders/getListOrders");
    await this.$store.dispatch("eans/getListEans");
    await this.$store.dispatch("user/getInfoUser");
  },
};
</script>
<style lang="scss" scoped>
.bg-custom {
  background-color: #f4f6fc;
}
</style>