<template lang="pug">
.sidebar(v-bind:class="{ active: isActive }")
  .menu-expander
    font-awesome-icon(:icon="['fas', 'align-left']", @click="toggle_sidebar")
  ul
    li
      router-link(exact, :to="{ name: 'Home' }")
        font-awesome-icon(:icon="['fas', 'th-large']")
        span {{ $t('globals.home') }}
    li
      router-link(:to="{ name: 'Eans' }")
        font-awesome-icon(:icon="['fas', 'barcode']")
        span {{ $t('globals.eans') }}
    li
      router-link(:to="{ name: 'Orders' }")
        font-awesome-icon(:icon="['fas', 'folder-open']")
        span {{ $t('globals.orders') }}
    li
      router-link(:to="{ name: 'Billing' }")
        font-awesome-icon(:icon="['fas', 'file-alt']")
        span {{ $t('globals.invoices') }}
    li
      router-link(:to="{ name: 'Profile' }")
        font-awesome-icon(:icon="['fas', 'user-edit']")
        span {{ $t('globals.profile') }}
  ul
    li.text-danger(@click="$store.dispatch('auth/logout')")
      font-awesome-icon(:icon="['fas', 'user-slash']")
      span Logout
</template>
<script>
export default {
  name: "navbar",
  data: () => ({
    isActive: false,
  }),

  methods: {
    toggle_sidebar() {
      this.isActive = !this.isActive;
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar {
  font-family: "Montserrat";
  @apply bg-white border-r border-blue-100 h-full flex justify-between flex-col;
  min-width: 250px;
  max-width: 250px;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 100vh;
  transition: all 0.3s;

  .menu-expander {
    font-size: 1.3rem;
    padding: 1.5rem;
    padding-left: 2rem;
    @apply text-gray-400;

    svg {
      cursor: pointer;
    }
  }

  ul {
    @apply font-bold text-sm mt-4 text-gray-400;
  }

  li {
    @apply cursor-pointer whitespace-nowrap flex;
    a {
      padding: 1rem;
      width: 100%;
      @apply border-0 flex items-center;
    }
    a.router-link-exact-active {
      border-left: 4px solid;
      @apply border-blue-600 text-blue-600;
    }

    &:hover {
      background-color: #eff2ff7d;
    }

    &.text-danger {
      padding: 1rem;
      display: block;
      width: 100%;
      @apply flex items-center text-red-400;
      &:hover {
        background-color: #dc35450d;
      }
    }
    svg {
      @apply text-2xl w-10;
      margin: 0px 1rem 0 0;
    }
  }

  &.active {
    min-width: 85px;
    max-width: 85px;
    li {
      span {
        opacity: 0;
      }
    }
  }

  @media (max-width: 992px) {
    min-width: calc(100% - 20px);
    box-shadow: 10px 0px 20px #e4e8f0;
    position: fixed;

    &.active {
      position: sticky;
      min-width: 50px;
      max-width: 50px;
      box-shadow: none;
    }
    .menu-expander {
      padding-left: 1rem;
    }

    li svg {
      @apply text-xl w-5;
    }
  }
}
</style>